import * as React from "react";
import glutenFree from "../images/dietary/gluten-free.svg";
import dairyFree from "../images/dietary/dairy-free.svg";
import vegan from "../images/dietary/vegan.svg";

function DietaryOptionIcon({ src, className, alt }) {
    const _className = className ? className : "max-h-11 p-1 ";
    return (
        <img src={src} className={_className} alt={alt} />
    )
}

export const DairyFreeIcon = ({ className }) => (<DietaryOptionIcon src={dairyFree} className={className} alt="Dairy Free Option" />)
export const GlutenFreeIcon = ({ className }) => (<DietaryOptionIcon src={glutenFree} className={className} alt="No Added Gluten Option" />)
export const VeganIcon = ({ className }) => (<DietaryOptionIcon src={vegan} className={className} alt="Vegan Option" />)

export function DietaryRequirementsSection({ className }) {
    return (
        <div className={className}>
            <div className="inline-flex flex-col sm:flex-row justify-center">
                <div className="flex flex-col sm:flex-row items-center mr-0 gap-1">
                    <div className="flex gap-1 md:mb-16 lg:mb-16">
                        <GlutenFreeIcon className='h-5' />
                        <VeganIcon className='h-5' />
                        <DairyFreeIcon className='h-5' />
                    </div>
                    <span className="font-gotu text-[0.83rem] tracking-wider text-gray-800 pl-1 pt-1 md:mb-16 lg:mb-16">No added gluten, vegan, and dairy-free options available</span>
                </div>
            </div>
        </div>
    )
}
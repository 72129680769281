import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DairyFreeIcon, GlutenFreeIcon, VeganIcon } from "./dietary-options";
function Cake({ cake }) {
    const sortedPrice = cake.options.map(x => x.price).sort((a, b) => a - b)
    const lowestPrice = (sortedPrice) ? sortedPrice[0] : 0
    const image = getImage(cake.imageFile)
    return (
        <div className="flex flex-col items-center md:px-4 mb-12 sm:mb-16">
            <GatsbyImage image={image} alt="Pasteleria Cake" className="h-70 w-70 sm:h-80 sm:w-80" />
            <div className="text-center md:text-left max-w-2xs px-2 py-5 flex flex-col text-xs">
                <div className="text-center ml-3">
                    <h3 className="font-ovo lowercase text-[1.18rem] tracking-wider text-gray-800 pb-2">{cake.name}</h3>
                    <p className="font-gotu lowercase text-[0.78rem] tracking-wide text-pas-gray">from ${lowestPrice}</p>
                </div>
            </div>

        </div>
    )
}

function DietaryOptions({ dietaryRequirements }) {
    const className = "max-h-8 p-1";
    return (
        <div className="inline-flex flex-row h-8">
            {(dietaryRequirements && dietaryRequirements.includes("gf")) ? <GlutenFreeIcon className={className} /> : <></>}
            {(dietaryRequirements && dietaryRequirements.includes("v")) ? <VeganIcon className={className} /> : <></>}
            {(dietaryRequirements && dietaryRequirements.includes("df")) ? <DairyFreeIcon className={className} /> : <></>}
        </div>
    )
}

export default Cake;
import { useStaticQuery, graphql } from "gatsby"

export const useCelebrationCakes = () => {
  const { celebrationCakes } = useStaticQuery(
    graphql`
        query AllStripeCelebrationCakesQuery {
            celebrationCakes: allStripeProduct(
              filter: {active: {eq: true}, metadata: {subType: {eq: "celebration"}}}
            ) {
                nodes {
                    active
                    id
                    images
                    livemode
                    name
                    description
                    object
                    
                    type
                    
                    localImage {
                      childImageSharp {
                        gatsbyImageData(height: 500, placeholder: BLURRED)
                      }                
                      publicURL
                    }
                    metadata {
                      code
                      subType
                      flavours
                      textures
                      decorations
                      cakeShapes
                      set1Colours
                      set2Colours
                      set3Colours
                      set4Colours
                      set5Colours
                      set6Colours
                      set7Colours
                      set8Colours
                      set9Colours
                      set10Colours
                      
                      
                      set1ColourLabel
                      set2ColourLabel
                      set3ColourLabel
                      set4ColourLabel
                      set5ColourLabel
                      set6ColourLabel
                      set7ColourLabel
                      set8ColourLabel
                      set9ColourLabel
                      set10ColourLabel
                             
                      dietaryRequirements
                      banner
      
                      sort
                    }
                  }
            }
          }
        `
  )
  return celebrationCakes?.nodes
}